<template>
  <tickets-provider
    v-bind="{
      clientId,
      dataScope,
      id,
      ignoreHistory,
      leadId,
      limit,
      manualFilters,
      showStaged
    }"
  >
    <template v-slot:default="{ $ticketsData, $ticketsMethods }">
      <div class="has-margin-bottom-50">
        <template v-if="showQuickSearch">
          <div class="field is-grouped">
            <div class="control is-expanded">
              <b-input
                :value="$ticketsData.searchQuery"
                :placeholder="$t('_placeholder.tickets_quick_search')"
                :icon-right="$ticketsData.searchQuery ? 'close-circle' : null"
                :icon-right-clickable="!!$ticketsData.searchQuery"
                @icon-right-click="$ticketsMethods.doQuickSearch()"
                @input="$ticketsMethods.debounceSearch"
              />
            </div>
          </div>
        </template>

        <filter-sort-controls
          v-if="$ticketsData.enableFiltering || $ticketsData.enableSorting"
          :active-filters="$ticketsData.activeFilters"
          :enable-filtering="$ticketsData.enableFiltering"
          :enable-sorting="$ticketsData.enableSorting"
          :filters="$ticketsData.availableFilters"
          :sorters="$ticketsData.availableSorters"
          :table="$ticketsData.table"
          class="has-margin-bottom-100"
          @onRefresh="$ticketsMethods.refreshInbox"
          @onFiltersChange="$ticketsMethods.applyFilters"
          @onSort="$ticketsMethods.onSort"
        />

        <div class="is-relative">
          <!-- No results -->
          <u-empty-state
            :active="!$ticketsData.isLoading && !$ticketsData.table.data.length"
          >
            <template #title>
              <i18n path="_sentence.no_tickets_found" />
            </template>
            <template #desc>
              <i18n path="_sentence.ticket.no_tickets_matching_filters_msg" />
            </template>
          </u-empty-state>

          <!-- Loading -->
          <b-loading :active="$ticketsData.isLoading" :is-full-page="false" />

          <!-- Skeleton -->
          <template
            v-if="$ticketsData.isLoading || !$ticketsData.table.data.length"
          >
            <tickets-list-item
              v-for="i in $ticketsData.table.data.length ||
              ($store.getters['ui/isMobile'] ? 2 : 6)"
              :key="`import-skeleton-${i}`"
              :is-loading="$ticketsData.isLoading"
            />
          </template>

          <!-- Results -->
          <template v-else>
            <tickets-list-item
              v-for="ticket in $ticketsData.tickets"
              :key="`ticket-item-${ticket.id}`"
              :ticket="ticket"
              :is-loading="$ticketsData.isLoading"
              :ticket-route="ticketRoute(ticket, isClient)"
              :date-field="$ticketsData.table?.sort?.field"
              :show-owner="showOwner"
            />
          </template>
        </div>
        <!-- Pagination -->
        <u-pagination
          :current.sync="$ticketsData.page"
          :per-page="$ticketsData.limit"
          :total="$ticketsData.total"
          :default-limit="$ticketsData.defaults.limit"
          :visible-total="$ticketsData.table.data.length"
          @change-page="$ticketsMethods.onPageChange"
          @change-per-page="$ticketsMethods.onLimitChange"
        />
      </div>
    </template>
  </tickets-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import Context from "@/mixins/context";
import { AdminRoutes, ClientRoutes } from "@/data/enums/router";
import type { IClient, ILead } from "@upmind-automation/types";
import type { ITicket } from "@upmind-automation/types";

const TicketsListing = defineComponent({
  name: "TicketsListing",
  components: {
    "tickets-provider": () =>
      import("@/components/app/global/tickets/ticketsProvider"),
    "tickets-list-item": () =>
      import("@/components/app/global/tickets/ticketsListItem.vue")
  },
  mixins: [Context],
  props: {
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    leadId: { type: String as PropType<ILead["id"]>, default: null },
    limit: { type: Number, default: undefined },
    id: { type: String, default: undefined },
    dataScope: { type: String, default: undefined },
    ignoreHistory: { type: Boolean, default: undefined },
    showOwner: { type: Boolean, default: false },
    routeFilters: {
      type: Function as PropType<
        (
          vm?: typeof TicketsListing
        ) => Record<string, string | number | boolean>
      >,
      default: () => {}
    },
    ticketRoute: {
      type: Function as PropType<
        (ticket: ITicket, isClient: boolean) => Record<string, any>
      >,
      default: (ticket: ITicket, isClient: boolean) => {
        if (!ticket) return {};
        return {
          name: isClient
            ? ClientRoutes.SUPPORT_TICKET
            : AdminRoutes.SUPPORT_TICKET,
          params: { ticketId: ticket.id }
        };
      }
    },
    showStaged: { type: Boolean, default: false },
    showQuickSearch: { type: Boolean, default: true }
  },
  computed: {
    manualFilters() {
      return { ...this.routeFilters(this) };
    }
  }
});

export default TicketsListing;
</script>
