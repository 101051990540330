<template>
  <u-ripple-table
    :data="$cProdsData.products.length ? $cProdsData.products : Array(5)"
    :is-loading="table.loading || $cProdsData.isLoading"
    :is-paginated="false"
    :is-hoverable="!!table.data.length"
    :limit="table.limit"
    :total="table.total"
    :current-page="table.page"
    table-class="contract-product-table"
  >
    <template slot="emptyMessage">
      <p class="is-size-7 has-text-grey">
        <i18n path="_sentence.no_results_matching_filters_msg" />
      </p>
      <template v-if="showCta">
        <p class="has-margin-top-100">
          <b-button
            class="has-theme-brand"
            @click="$cProdsMethods.startNewOrder"
          >
            <i18n path="_action.place_new_order" />
          </b-button>
        </p>
      </template>
    </template>

    <template v-if="showTableHeader" slot="thead">
      <tr>
        <th colspan="2">
          {{ [$t("_.product"), $t("_.service")].join(" / ") }}
        </th>
        <th v-if="isSubscription" class="has-text-right is-hidden-touch">
          {{ $t("_.billing_term") }}
        </th>
      </tr>
    </template>

    <template v-if="table.loading || !table.data.length" v-slot="{ index }">
      <tr :key="`skeleton-row-${index}`" class="cprod-row">
        <div class="cprod-wrapper">
          <div class="cprod-media">
            <cprod-image :size="54" :is-loading="table.loading" />
          </div>
          <div class="cprod-name has-padding-y-25">
            <b-skeleton :animated="table.loading" width="12rem" />
          </div>
          <div class="cprod-client has-padding-y-25">
            <b-skeleton
              :animated="table.loading"
              width="14rem"
              size="is-small"
            />
          </div>
          <div class="cprod-term has-padding-y-25">
            <b-skeleton :animated="table.loading" width="10rem" />
          </div>
          <div class="cprod-status has-padding-y-25">
            <b-skeleton
              :animated="table.loading"
              width="8rem"
              size="is-small"
            />
          </div>
          <div class="cprod-chevron">
            <b-skeleton width="16" height="16" :animated="table.loading" />
          </div>
        </div>
      </tr>
    </template>

    <template v-else v-slot="{ index, row: cProd }">
      <component
        :is="rowTag"
        :key="`row-${index}`"
        :contract-id="cProd.contract_id"
        :product-id="cProd.id"
        :client-id="clientId ? clientId : $_.get(cProd, 'clients[0].id')"
        :to="to ? to : undefined"
        class="cprod-row"
        v-on="rowEvents(cProd)"
        :redirect="redirect"
      >
        <div class="cprod-wrapper">
          <!-- Product image + client avatar -->
          <div class="cprod-media">
            <cprod-image
              :client="$_.get(cProd, 'clients[0]')"
              :contract-product="cProd"
              :size="54"
            />
          </div>
          <!-- Product name -->
          <div class="cprod-name">
            <span>{{ getCategoryName(cProd) }} /</span>
            <strong>{{ getProductName(cProd) }}</strong>
            <cprod-original-name v-bind="{ cProd }" class="has-text-grey" />

            <b-tooltip
              v-if="hasUnresolvedRequests(cProd)"
              :label="`${$_.get(
                cProd,
                'unresolved_provision_requests[0].function_name'
              )}:
              ${$_.get(
                cProd,
                'unresolved_provision_requests[0].result_message'
              )}`"
              position="is-left"
              :multilined="true"
              type="is-dark"
              @click.native.stop
            >
              <router-link :to="getProvisioningRoute(cProd)" @click.native.stop>
                <b-icon
                  icon="exclamation-triangle"
                  size="is-small"
                  type="is-danger"
                  class="has-margin-left-25"
                />
              </router-link>
            </b-tooltip>

            <b-tooltip
              v-else-if="isAwaitingSetup(cProd)"
              :label="$t('_sentence.cprod.is_pending_setup_tooltip')"
              position="is-left"
              type="is-warning"
              @click.native.stop
            >
              <router-link :to="getSetupRoute(cProd)" @click.native.stop>
                <b-icon
                  icon="exclamation-triangle"
                  size="is-small"
                  type="is-warning"
                  class="has-margin-left-25"
                />
              </router-link>
            </b-tooltip>
          </div>
          <!-- Client details -->
          <div class="cprod-client">
            <p class="is-size-7 has-text-grey">
              <template v-if="isAdmin && !clientId && !accountId">
                <u-client-link
                  :key="`client_${$_.get(cProd, 'clients[0].id')}`"
                  :client="$_.get(cProd, 'clients[0]')"
                  :client-id="$_.get(cProd, 'clients[0].id')"
                  :truncate="20"
                  to="adminClientCProds"
                  class="has-text-grey"
                  @click.native.stop
                />
                <span v-if="isMultibrand" class="has-text-grey">
                  ({{ getBrandName($_.get(cProd, "clients[0].brand_id")) }})
                </span>
              </template>
              <span
                v-if="showWhenPurchased"
                :class="{
                  'is-hidden-touch': isAdmin && !clientId && !accountId,
                  'is-hidden-desktop-only': isAdmin && !clientId && !accountId
                }"
              >
                <template v-if="isAdmin && !clientId && !accountId"
                  >&bull;</template
                >
                <template v-if="cProd.is_delegated_object">
                  <i18n path="_.purchased_on_by_actor" class="has-text-grey">
                    <template #on>
                      <datetime
                        :key="cProd.id"
                        :datetime="cProd.created_at"
                        format-function="calendar"
                      />
                    </template>
                    <template #actor>{{
                      $_.get(
                        cProd,
                        "clients[0].public_name",
                        $t("_.another_user").toString().toLocaleLowerCase()
                      )
                    }}</template>
                  </i18n>

                  <u-delegated-object-icon
                    :object-type="$t('_.product')"
                    class="has-margin-left-25"
                  />
                </template>
                <template v-else>
                  <i18n path="_.purchased_on" class="has-text-grey">
                    <template #on>
                      <datetime
                        :key="cProd.id"
                        :datetime="cProd.created_at"
                        format-function="calendar"
                        class="has-text-grey"
                      />
                    </template>
                  </i18n>
                </template>
                <span
                  v-if="isSubscription && !isMultibrand"
                  class="is-hidden-widescreen-only"
                  >{{
                    `(${$store.getters["utils/money/trimTrailingZeros"](
                      $_.get(
                        cProd,
                        "configuration_total_discounted_amount_formatted"
                      )
                    )})`
                  }}</span
                >
              </span>
            </p>

            <!-- cProd Tags -->
            <cprod-tags tag="p" class="has-margin-top-25" v-bind="{ cProd }" />
          </div>

          <!-- Product term -->
          <div class="cprod-term">
            <template v-if="isOnTerminatingTrial(cProd)">
              <i18n tag="strong" path="_.x_day_free_trial">
                <template #dayCount>{{ cProd.trial_duration }}</template>
              </i18n>
            </template>

            <template v-else>
              <u-price-converted-tooltip
                v-if="isConvertedPrice(cProd)"
                :xr="cProd.base_price_exchange_rate"
                :base-currency="cProd.product.brand.currency.code"
                :converted-currency="cProd.base_currency_code"
                position="is-left"
                class="has-margin-right-25"
              />
              <u-price-manual-tooltip
                v-else-if="isManualPrice(cProd)"
                position="is-left"
                class="is-size-7 has-margin-right-25"
              />
              <strong>{{ getPriceTermSummary(cProd) }}</strong>
            </template>
          </div>
          <!-- Product status -->
          <div class="cprod-status is-size-7">
            <component :is="getSummary(cProd)" />
          </div>
          <!-- Chevron -->
          <div class="cprod-chevron">
            <b-icon icon="chevron-right" size="is-small" />
          </div>
        </div>
      </component>
    </template>
  </u-ripple-table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import MultilevelCategoriesFetcher from "@/components/app/admin/catalogue/products/multilevelCategories/multilevelCategoriesFetchMixin";
import BillingCyclesStore from "@/mixins/constants/billingCyclesStore";
import AdminBrands from "@/mixins/adminBrands";
import CustomFieldsMixin from "@/mixins/customFieldsMixin";
import TagsMixin from "@/mixins/tagsMixin";
import CProdMixin from "@/mixins/cProdMixin";

import { DataModules } from "@/store/modules/data/modules";
import type { PropType, CreateElement } from "vue";
import type {
  IContractProduct,
  IContractProductCondition
} from "@/models/contracts";
import type { IClient } from "@upmind-automation/types";
import type { IAccount } from "@/models/accounts";
import type {
  ICProdsProviderData,
  ICProdsProviderMethods
} from "@/models/providers/contractProduct";
import type { IRoute } from "@/models/routes";

export default defineComponent({
  name: "CProdsListingTableView",
  components: {
    "add-segment-message": () =>
      import("@/components/app/admin/segments/addSegmentMessage.vue"),
    "cprod-image": () =>
      import("@/components/app/global/contractProducts/cProdImage.vue"),
    "cprod-tags": () =>
      import("@/components/app/global/contractProducts/cProdTags.vue"),
    "cprod-original-name": () =>
      import("@/components/app/global/contractProducts/cProdOriginalName.vue")
  },
  mixins: [
    TagsMixin,
    CustomFieldsMixin,
    BillingCyclesStore,
    AdminBrands,
    MultilevelCategoriesFetcher,
    CProdMixin
  ],
  inject: {
    cProdsProvider: {
      from: "cProdsProvider",
      type: Function as PropType<ICProdsProviderData>
    },
    $cProdsMethods: {
      from: "cProdsProviderMethods",
      type: Object as PropType<ICProdsProviderMethods>
    }
  },
  props: {
    rowTag: { type: String, default: "u-contract-product-link" },
    isSubscription: { type: Boolean, default: false },
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    accountId: { type: String as PropType<IAccount["id"]>, default: null },
    rowEvents: {
      type: Function as PropType<
        (contractProduct: IContractProduct) => {
          [key: string]: Function;
        }
      >,
      default: () => {}
    },
    showWhenPurchased: { type: Boolean, default: true },
    showTableHeader: { type: Boolean, default: true },
    to: { type: Object as PropType<any>, default: null },
    redirect: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters({
      isMultibrand: "brand/isMultibrand"
    }),
    $cProdsData() {
      return this.cProdsProvider();
    },
    table() {
      return this.$cProdsData.table;
    },
    showCta() {
      return (
        this.$store.getters["brand/showStore"] && !this.isMockClientContext
      );
    }
  },
  methods: {
    hasUnresolvedRequests(cProd) {
      return !!cProd.unresolved_provision_requests?.length;
    },
    isAwaitingSetup(cProd) {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isAwaitingSetup`
      ](cProd);
    },
    getSetupRoute(cProd: IContractProduct) {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/routeToSetup`
      ](cProd)?.to as IRoute["to"];
    },
    getProvisioningRoute(cProd: IContractProduct) {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/routeToProvisioning`
      ](cProd)?.to as IRoute["to"];
    },
    getSummary(cProd: IContractProduct) {
      const condition = this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/conditionSummary`
      ](cProd) as IContractProductCondition;
      return {
        render: (h: CreateElement) => {
          if (!condition) return h("span", cProd.status?.name);
          return h(condition.summary, {
            class: ["is-size-7", condition.type.replace("is-", "has-text-")]
          });
        }
      };
    }
  }
});
</script>

<style lang="scss" scoped>
.contract-product-table::v-deep {
  position: relative;
  width: 100%;
  table,
  tbody,
  thead {
    width: 100%;
    display: block;
  }

  thead {
    tr {
      display: grid;
      grid-template-columns: 1fr auto 1rem;
      grid-column-gap: 1rem;
      background: $light;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;
      th {
        font-size: 13px;
        text-transform: uppercase;
        color: $grey;
      }
    }
  }

  .cprod-row {
    display: block;
    transition: border-top-color ease-out 0.12s;
    border-top: 1px solid $border;
  }

  .is-hoverable .cprod-row:hover {
    border-top-color: rgba($border, 0.5);
    .cprod-wrapper {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      box-shadow: 0 10px 10px -12px rgba($black, 0.3),
        0 -10px 10px -12px rgba($black, 0.3);
      .cprod-chevron {
        color: $text;
      }
    }
  }

  .cprod-wrapper {
    display: grid;
    padding: 0.75rem 1rem;
    grid-column-gap: 1rem;
    color: $text;
    transition: all ease-out 0.12s;
  }

  .cprod-media {
    padding: 0.3125rem 0;
    align-self: center;
  }

  .cprod-chevron {
    color: $grey-light;
    transition: color ease-out 0.2s;
  }

  @include touch {
    .cprod-wrapper {
      grid-template-columns: 3.375rem 1fr 1rem;
      .cprod-media {
        grid-column: 1;
        grid-row: 1 / span 4;
      }
      .cprod-name {
        grid-column: 2;
        grid-row: 1;
      }
      .cprod-term {
        grid-column: 2;
        grid-row: 2;
      }
      .cprod-status {
        grid-column: 2;
        grid-row: 3;
      }
      .cprod-client {
        grid-column: 2;
        grid-row: 4;
      }
      .cprod-chevron {
        grid-column: 3;
        grid-row: 1 / span 1;
        align-self: center;
      }
    }
  }

  @include desktop {
    .cprod-wrapper {
      grid-template-columns: 3.375rem 1fr auto 1rem;
      .cprod-media {
        grid-column: 1;
        grid-row: 1 / span 4;
      }
      .cprod-name {
        grid-column: 2;
        grid-row: 2;
        align-self: flex-end;
      }
      .cprod-client {
        grid-column: 2;
        grid-row: 3;
        align-self: start;
      }
      .cprod-term {
        grid-column: 3;
        grid-row: 2;
        text-align: right;
        align-self: flex-end;
      }
      .cprod-status {
        grid-column: 3;
        grid-row: 3;
        text-align: right;
        align-self: start;
      }
      .cprod-status,
      .cprod-term {
        .b-skeleton {
          display: flex;
          justify-content: flex-end;
        }
      }
      .cprod-chevron {
        grid-column: 4;
        grid-row: 3;
      }
    }
  }

  .u-ripple-table-empty {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 20rem;
    box-shadow: 0 4px 16px 0 rgba($black, 0.1);
    & ~ * {
      opacity: 0.5;
      border-top-color: $light !important;
    }
  }
}
</style>
