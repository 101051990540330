<template>
  <earth-layout>
    <template slot="A">
      <u-header :title="$t('_.products_and_services')">
        <tabigation
          slot="tabs"
          :routes="routes()"
          class="has-margin-bottom-200"
        />
        <template #cta>
          <b-button :to="{ name: 'adminCatalogueProducts' }" tag="router-link">
            <i18n path="_action.manage_product_catalogue" />
          </b-button>
          <template v-if="canAddOrder">
            <b-button
              type="is-dark"
              @click="$store.dispatch('data/contracts/startNewOrder')"
            >
              <i18n path="_action.place_new_order" />
            </b-button>
          </template>
        </template>
      </u-header>
      <router-view />
    </template>
  </earth-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RequestPromises from "@/mixins/requestPromises";
import type { PropType } from "vue";

export default defineComponent({
  name: "AdminProductsAndServices",
  mixins: [RequestPromises],
  props: {
    routes: { type: Function as PropType<() => any>, default: () => {} }
  },
  computed: {
    canAddOrder() {
      // list_clients because a client must be selected
      return this.$userCan("staff_create_order", "list_clients");
    }
  }
});
</script>
