import type { GetterTree } from "vuex";
import type {
  IContractProduct,
  IContractProductCondition
} from "@/models/contracts";
import type { IDataState } from "@/store/modules/data";
import type { IState } from "@/store";
import type { Component, CreateElement, VNode } from "vue";
import { AdminRoutes, ClientRoutes } from "@/data/enums/router";
import i18n from "@/i18n";
import { $moment } from "@/boot/moment-js";
import type {
  ICProdProviderMethods,
  ICProdProvProviderMethods
} from "@/models/providers/contractProduct";
import CProdActionControl from "@/components/app/global/contractProducts/cProdActionControl.vue";
import type { IRoute } from "@/models/routes";
import type { TranslateResult } from "vue-i18n";
import type { Route } from "vue-router";

interface IActionControlParams {
  $cProdMethods: ICProdProviderMethods;
  $cProdProvMethods: ICProdProvProviderMethods;
}

function buildAction(h: CreateElement, route: IRoute, label: TranslateResult) {
  if (route.disabled) return;
  return h("u-link", { props: route }, label as string);
}

function $i18n(path: string, slots: (h: CreateElement) => VNode[] = () => []) {
  return {
    render: (h: CreateElement) => h("i18n", { props: { path } }, slots(h))
  } as Component;
}

export const getters: GetterTree<IDataState, IState> = {
  conditionSummary:
    (state, getters) =>
    (
      cProd: IContractProduct,
      $route: Route
    ): IContractProductCondition | null => {
      const entity = getters.entity(cProd);
      const statusName = cProd?.status?.name as string;

      // IS PENDING (AWAITING PAYMENT)
      // **************************************************************

      if (getters.isPending(cProd))
        return {
          label: $i18n("_sentence.cprod.is_awaiting_payment_label"),
          summary: $i18n("_sentence.cprod.is_awaiting_payment_summary"),
          description: $i18n("_sentence.cprod.is_awaiting_payment_desc", h => [
            h("span", { slot: "entity" }, entity),
            h("span", { slot: "orderNum" }, cProd?.main_invoice_number)
          ]),
          action: h =>
            buildAction(
              h,
              getters.routeToOrder(cProd),
              i18n.t("_action.go_to_order")
            ),
          type: "is-warning",
          icon: "exclamation-circle"
        };

      // IS EXPIRING
      // **************************************************************

      if (getters.hasAutoExpireEnabled(cProd)) {
        return {
          label: $i18n("_sentence.cprod.is_expiring_label", h => [
            h("span", { slot: "statusName" }, statusName)
          ]),
          summary: $i18n("_sentence.cprod.is_expiring_summary", h => [
            h("span", { slot: "statusName" }, statusName),
            h("datetime", {
              slot: "relativeDate",
              props: {
                datetime: cProd?.calculated_cancel_date,
                formatFunction: "calendar"
              }
            })
          ]),
          description: $i18n("_sentence.cprod.is_expiring_desc", h => [
            h("span", { slot: "entity" }, entity),
            h(
              "span",
              { slot: "absoluteDate" },
              $moment
                .utc(cProd?.calculated_cancel_date)
                .format(i18n.t("_datetime.date.default") as string)
            )
          ]),
          action: h =>
            h(
              CProdActionControl,
              {
                key: "toggleAutoExpire",
                class: "has-text-danger",
                props: {
                  action: ({ $cProdMethods }: IActionControlParams) =>
                    $cProdMethods.toggleAutoExpire(false)
                }
              },
              i18n.t("_action.dont_cancel") as string
            ),
          type: "is-danger",
          icon: "hourglass-half"
        };
      }

      // IS PENDING HARD CANCELLATION (ACTIVE REQUEST)
      // **************************************************************

      if (getters.hasHardCancellationRequest(cProd)) {
        return {
          label: $i18n("_sentence.cprod.is_pending_cancellation_label", h => [
            h("span", { slot: "statusName" }, statusName)
          ]),
          summary: $i18n(
            "_sentence.cprod.is_pending_cancellation_summary",
            h => [h("span", { slot: "statusName" }, statusName)]
          ),
          description: $i18n(
            "_sentence.cprod.is_pending_cancellation_desc",
            h => [h("span", { slot: "entity" }, entity)]
          ),
          action: h =>
            h(
              CProdActionControl,
              {
                key: "deleteCancellationRequest",
                class: "has-text-danger",
                props: {
                  action: ({ $cProdMethods }: IActionControlParams) =>
                    $cProdMethods.deleteCancellationRequest(
                      cProd?.contract_request?.id
                    )
                }
              },
              i18n.t("_action.dont_cancel") as string
            ),
          type: "is-danger",
          icon: "exclamation-triangle"
        };
      }

      // IS AWAITING SETUP
      // **************************************************************

      if (getters.isAwaitingSetup(cProd))
        return {
          label: $i18n("_sentence.cprod.is_awaiting_setup_label"),
          summary: $i18n("_sentence.cprod.is_awaiting_setup_summary"),
          description: $i18n("_sentence.cprod.is_awaiting_setup_desc", h => [
            h("span", { slot: "entity" }, entity)
          ]),
          type: "is-warning",
          action: h => {
            const hideFrom = [
              AdminRoutes.CONTRACT_PRODUCT_SETUP,
              ClientRoutes.CONTRACT_PRODUCT_SETUP
            ];
            if (hideFrom.includes($route?.name as any)) return;
            return buildAction(
              h,
              getters.routeToSetup(cProd),
              i18n.t("_action.complete_setup")
            );
          },
          icon: "asterisk"
        };

      // IS AWAITING ACTIVATION
      // **************************************************************

      if (getters.isAwaitingActivation(cProd))
        return {
          label: $i18n("_sentence.cprod.is_awaiting_activation_label"),
          summary: $i18n("_sentence.cprod.is_awaiting_activation_summary"),
          description: $i18n(
            "_sentence.cprod.is_awaiting_activation_desc",
            h => [h("span", { slot: "entity" }, entity)]
          ),
          type: "is-info",
          icon: "sync-alt"
        };

      // IS SUSPENDED
      // **************************************************************

      if (getters.isSuspended(cProd))
        return {
          label: $i18n("_sentence.cprod.is_suspended_label"),
          summary: $i18n("_sentence.cprod.is_suspended_summary", h => [
            h("datetime", {
              slot: "relativeDate",
              props: {
                datetime: cProd?.next_due_date,
                formatFunction: "fromNow",
                formatFunctionParams: true
              }
            })
          ]),
          description: $i18n("_sentence.cprod.is_suspended_desc", h => [
            h("span", { slot: "entity" }, entity)
          ]),
          type: "is-danger",
          icon: "exclamation-circle",
          action: h =>
            buildAction(
              h,
              getters.routeToBilling(cProd),
              i18n.t("_action.view_invoices")
            )
        };

      // IS FULFILLED ONE-TIME PURCHASE
      // **************************************************************

      if (getters.isActive(cProd) && !getters.isSubscription(cProd))
        return {
          label: $i18n("_sentence.cprod.is_fulfilled_label"),
          summary: $i18n("_sentence.cprod.is_fulfilled_summary", h => [
            h("datetime", {
              slot: "relativeDate",
              props: {
                datetime: cProd?.activation_date,
                formatFunction: "calendar"
              }
            })
          ]),
          description: $i18n("_sentence.cprod.is_fulfilled_desc", h => [
            h("span", { slot: "entity" }, entity),
            h(
              "span",
              { slot: "absoluteDate" },
              $moment
                .utc(cProd?.activation_date)
                .format(i18n.t("_datetime.date.default") as string)
            )
          ]),
          type: "is-success",
          icon: "check-circle"
        };

      // IS ACTIVE TRIAL SET TO CANCEL AFTER TRIAL PERIOD
      // **************************************************************

      if (getters.isActive(cProd) && getters.isOnTerminatingTrial(cProd))
        return {
          label: $i18n(
            "_sentence.cprod.is_active_with_terminating_trial_label"
          ),
          summary: $i18n(
            "_sentence.cprod.is_active_with_terminating_trial_summary",
            h => [
              h("datetime", {
                slot: "relativeDate",
                props: {
                  datetime: cProd?.trial_end_date,
                  formatFunction: "calendar"
                }
              })
            ]
          ),
          description: $i18n(
            "_sentence.cprod.is_active_with_terminating_trial_desc",
            h => [
              h("span", { slot: "entity" }, entity),
              h("datetime", {
                slot: "relativeDate",
                props: {
                  datetime: cProd?.trial_end_date,
                  formatFunction: "calendar"
                }
              })
            ]
          ),
          type: "is-info",
          icon: "stopwatch"
        };

      // IS ACTIVE WITH UNPAID INVOICES
      // **************************************************************

      if (getters.isActive(cProd) && getters.hasUnpaidRecurringInvoices(cProd))
        return {
          label: $i18n("_sentence.cprod.is_active_with_payment_due_label"),
          summary: $i18n("_sentence.cprod.is_active_with_payment_due_summary"),
          description: $i18n(
            "_sentence.cprod.is_active_with_payment_due_desc",
            h => [h("span", { slot: "entity" }, entity)]
          ),
          action: h =>
            buildAction(
              h,
              getters.routeToBilling(cProd),
              i18n.t("_action.view_invoices")
            ),
          type: "is-warning",
          icon: "exclamation-circle"
        };

      // IS ACTIVE WITH AUTO-RENEW OFF
      // **************************************************************

      if (getters.isActive(cProd) && getters.hasAutoRenewDisabled(cProd))
        return {
          label: $i18n("_sentence.cprod.is_active_with_auto_renew_off_label"),
          summary: $i18n(
            "_sentence.cprod.is_active_with_auto_renew_off_summary",
            h => [
              h("datetime", {
                slot: "relativeDate",
                props: {
                  datetime: cProd?.next_due_date,
                  formatFunction: "calendar"
                }
              })
            ]
          ),
          description: $i18n(
            "_sentence.cprod.is_active_with_auto_renew_off_desc",
            h => [
              h("span", { slot: "entity" }, entity),
              h(
                "span",
                { slot: "absoluteDate" },
                $moment
                  .utc(cProd?.next_due_date)
                  .format(i18n.t("_datetime.date.default") as string)
              )
            ]
          ),
          action: h =>
            h(
              CProdActionControl,
              {
                key: "toggleAutoRenew",
                props: {
                  action: ({ $cProdMethods }: IActionControlParams) =>
                    $cProdMethods.toggleAutoRenew(true)
                }
              },
              i18n.t("_action.turn_on_auto_renew") as string
            ),
          type: "is-warning",
          icon: "exclamation-circle"
        };

      // IS ACTIVE WITH AUTO-RENEW ON
      // **************************************************************

      if (getters.isActive(cProd))
        return {
          label: $i18n("_sentence.cprod.is_active_with_auto_renew_on_label"),
          summary: $i18n(
            "_sentence.cprod.is_active_with_auto_renew_on_summary",
            h => [
              h("datetime", {
                slot: "relativeDate",
                props: {
                  datetime: cProd?.next_due_date,
                  formatFunction: "calendar"
                }
              })
            ]
          ),
          description: $i18n(
            "_sentence.cprod.is_active_with_auto_renew_on_desc",
            h => [
              h("span", { slot: "entity" }, entity),
              h(
                "span",
                { slot: "absoluteDate" },
                $moment
                  .utc(cProd?.next_due_date)
                  .format(i18n.t("_datetime.date.default") as string)
              )
            ]
          ),
          type: "is-success",
          icon: "check-circle"
        };

      // IS CANCELLED
      // **************************************************************

      if (getters.isCancelled(cProd))
        return {
          label: $i18n("_sentence.cprod.is_cancelled_label"),
          summary: $i18n("_sentence.cprod.is_cancelled_summary", h => [
            h("datetime", {
              slot: "relativeDate",
              props: {
                datetime: cProd?.cancelled_date,
                formatFunction: "calendar"
              }
            })
          ]),
          description: $i18n("_sentence.cprod.is_cancelled_desc", h => [
            h("span", { slot: "entity" }, entity),
            h(
              "span",
              { slot: "absoluteDate" },
              $moment
                .utc(cProd?.cancelled_date)
                .format(i18n.t("_datetime.date.default") as string)
            )
          ]),
          type: "is-grey",
          icon: "times-circle"
        };

      // IS LAPSED
      // **************************************************************

      if (getters.isLapsed(cProd))
        return {
          label: $i18n("_sentence.cprod.is_lapsed_label"),
          summary: $i18n("_sentence.cprod.is_lapsed_summary", h => [
            h("datetime", {
              slot: "relativeDate",
              props: {
                datetime: cProd?.closed_date,
                formatFunction: "calendar"
              }
            })
          ]),
          description: $i18n("_sentence.cprod.is_lapsed_desc", h => [
            h("span", { slot: "entity" }, entity),
            h(
              "span",
              { slot: "absoluteDate" },
              $moment
                .utc(cProd?.closed_date)
                .format(i18n.t("_datetime.date.default") as string)
            )
          ]),
          type: "is-dark",
          icon: "ban"
        };

      return null;
    }
};
